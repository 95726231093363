.App {
  text-align: center;
}

.uploadBox {
  margin: auto;
  width: 800px;
}
.hbox {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 18px;
  flex-wrap: nowrap;
}
.login-wrapper {
  margin: auto;
  width: 40%;
  border: 3px solid green;
  padding: 10px;
}
.login-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.vbox {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  background: #060606;
}
.Vbox-text {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.login-page-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.login-page-header-titles {
  margin-right: 1rem;
}
.text-field {
  margin-bottom: 28px;
}
.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #7bc5c1;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: #688b8a;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #7bc5c1;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
  margin: 8px;
}
input[type='file'] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #16b9ba;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type='file']::file-selector-button:hover {
  background: #0d45a5;
}
.surfer {
  background: black;
  /* height: 90vh; */
  display: flex;
  flex-direction: column;
  padding: 18px;
}
.armagnac {
  background: #7bc5c1; /* set background color */
  height: 10vh; /* set height to 50% of viewport height */
  position: absolute;
  bottom: 0;
  width: 100%;
}

.remove-btn {
  margin-left: 18px;
}
.fadedBlue {
  /* background-color: #a1e3e3; */
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(26, 227, 227, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );

  width: 100vw;
  min-height: 100vh;
}
.m12 {
  margin: 12px;
}
.black-color {
  color: black;
}
.image-wrapper {
  width: 400px;
  height: 400px;
}
.link-color {
  color: rgba(0, 0, 0, 0.87);
}
.response-text {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: small;
  color: #7bc5c1;
}

:root {
  --input-wrapper-flex-direction: row;
}

.input-wrapper {
  display: flex;
  flex-direction:  var(--input-wrapper-flex-direction);
  justify-content: space-around;
  margin-top: 20px;
  align-items: center;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  color: orange;
}
.MuiStepLabel-label.Mui-completed {
  color: orange;
}

.MuiStepLabel-label.Mui-active {
  color: orange;
}
.css-1hv8oq8- .MuiSvgIcon-root-MuiStepIcon-root {
  color: orange;
}
.img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}
.cards {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}
.MuiInputBase-input-MuiOutlinedInput-input {
  color: '#7bc5c1';
}
.MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: '#7bc5c1';
  border: '2px solid #7bc5c1';
}
.MuiButtonBase-root-MuiButton-root {
  color: '#7bc5c1';
  border: '2px solid';
}

.edit-dlt-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:root {
  --left-width: 250px;
 }
.left {
  width:var(--left-width);
  border-right: 2px solid #7bc5c1;
  padding: 12px;
}
.chats-paper {
  width: 200px;
  max-width: 100%;
  margin-top: 12px;
  position: sticky;
  top: 15px;
}
#upload-btn-wrapper {
  margin: 12px;
}
@media only screen and (min-width: 1200px) {
  .drop-container {
    width: 440px;
  }
}
@media only screen and (max-width: 600px) {
  .hbox {
    flex-direction: column;
  }
  .MuiPaper-root-MuiCard-root {
    height: 100%;
  }
  /* .btn-text {
    display: none;
  } */
  .edit-dlt-wrapper {
    display: flex;
    flex-direction: column;
  }
  :root {
    --left-width: 150px;
   }
  .left {
    width: var(--left-width);
  }
  .chats-paper {
    width: 140px;
    max-width: 100%;
    position: sticky;
    top: 15px;
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
  }
}
