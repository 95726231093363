.div-form-stripe {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
}

.form-stripe {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 400px;
  height: 260px;
  border: solid 3px #c38058;
  border-radius: 12px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #73d5d5;
  margin-top: 24px;
}

.btn-stripe {
  width: 155px;
  margin: 0 auto;
  padding: 5px;
  background-color: #c38058;
  border-radius: 5px;
}

.btn-stripe:hover {
  cursor: pointer;
  background-color: rgb(65, 64, 64);
  color: white;
  border-radius: 2px;
}
.faded {
  background-color: #73d5d5;
}
.wrapper {
  border: 2px solid #00f;
  border-radius: 8px;
  padding: 20px 4px;
}
.black-color {
  color: black;
}
.m36 {
  margin: 36px;
}
.pay-btn {
  width: 175px;
  margin: auto;
  padding: 5px;
  border-radius: 5px;
  justify-content: center;
}
